<template>
  <div class="slip-container">
    <div class="d-flex align-items-center justify-content-center">
      <div class="mr-auto">
          <img :src="`/${configSchool.logoCompress}`" width="70px" alt />
      </div>
      <div class="text-center">
          <h3 class="text-uppercase font-weight-bold font-size-14 mb-1">{{configSchool.name3}}</h3>
          <h3 class="font-size-10">{{configSchool.address}} {{configSchool.city}}</h3>
          <h3 class="font-size-10">{{configSchool.number}}</h3>
      </div>
      <div class="ml-auto invisible">
          <img :src="`/${configSchool.logoCompress}`" width="70px" alt />
      </div>
    </div>
      <div class="hr"></div>
      <div class="text-center">
          <h3 class="text-uppercase font-weight-bold font-size-14">SLIP GAJI PEGAWAI</h3>
          <h3 v-if="slipGaji.rekap_absen" class="text-uppercase font-weight-normal font-size-12 mt-2">{{parsingDate(slipGaji.rekap_absen.periode.tanggal_awal)}} - {{parsingDate(slipGaji.rekap_absen.periode.tanggal_akhir)}}</h3>
      </div>
      <table v-if="slipGaji.pegawai" class="table table-borderless mt-3 w-50">
        <tr>
          <td>NIR</td>
          <td>:</td>
          <td class="font-weight-bold">{{slipGaji.pegawai.nik}}</td>
        </tr>
        <tr>
          <td>Nama</td>
          <td>:</td>
          <td class="font-weight-bold">{{slipGaji.pegawai.nama}}</td>
        </tr>
        <tr>
          <td>Jabatan</td>
          <td>:</td>
          <td class="font-weight-bold">
            <span v-for="(item, index) in slipGaji.pegawai.jabatan_pegawais" :key="index">{{item.jabatan.nama}}{{slipGaji.pegawai.jabatan_pegawais.length > 1 ? ', ' : ''}}</span>
          </td>
        </tr>
        <tr>
          <td>Status</td>
          <td>:</td>
          <td class="font-weight-bold">Pegawai {{slipGaji.pegawai.status_pegawai}}</td>
        </tr>
      </table>
      <template v-if="slipGaji.data">
        <div class="d-flex">
          <div class="w-50">
            <table class="table table-borderless">
              <tr>
                <td class="font-weight-bold text-uppercase"><u>Penghasilan</u></td>
              </tr>
              <tr>
                <td class="w-75">Gaji Pokok</td>
                <td>= </td>
                <td class="text-right">{{thousandPrice(slipGaji.data.gaji_pokok)}}</td>
              </tr>
              <tr v-for="item in slipGaji.data.penghasilan" :key="item.nama">
                <td class="w-75">{{underscoreParser(item.nama)}}</td>
                <td>= </td>
                <td class="text-right">{{thousandPrice(item.hasil)}}</td>
              </tr>
            </table>
          </div>
          <div class="mx-4"></div>
          <table class="table table-borderless w-50">
            <tr>
              <td class="font-weight-bold text-uppercase"><u>Pengurangan</u></td>
            </tr>
            <tr v-for="item in slipGaji.data.pengurangan" :key="item.nama">
                <td class="w-75">{{item.nama}}</td>
                <td>= </td>
                <td class="text-right">{{thousandPrice(item.hasil)}}</td>
              </tr>
          </table>
        </div>
        <div class="d-flex">
          <table class="table table-borderless">
              <tr class="font-weight-bold">
                <td class="text-uppercase">Total Penghasilan (A)</td>
                <td></td>
                <td class="text-right">Rp. {{thousandPrice(slipGaji.data.total_penghasilan)}}</td>
              </tr>
            </table>
            <div class="mx-4"></div>
          <table class="table table-borderless">
              <tr class="font-weight-bold">
                <td class="text-uppercase">Total Pengurangan (B)</td>
                <td></td>
                <td class="text-right">Rp. {{thousandPrice(slipGaji.data.total_pengurangan)}}</td>
              </tr>
            </table>
        </div>
        <div class="hr"></div>
        <div class="mt-3 w-50 pr-4">
          <table class="table table-borderless">
              <tr>
                <td class="text-uppercase"><h3 class="font-size-16 font-weight-bold">Gaji Diterima (A - B)</h3></td>
                <td></td>
                <td class="text-right"><h3 class="font-size-16 font-weight-bold">Rp. {{thousandPrice(slipGaji.data.total_gaji)}}</h3></td>
              </tr>
            </table>
        </div>
      </template>
  </div>
</template>

<script>
import moment from 'moment'
import thousandPrice from '@/helpers/thousandPrice'
export default {
  props: {
    configSchool: {
      type: Object,
    },
    slipGaji: {
      required: false,
    },
  },
  data() {
    return {
      thousandPrice,
    }
  },
  mounted() {
  },
  methods: {
    underscoreParser(string) {
      const data = string.split('_')
      let newString = ''
      data.forEach(el => {
        if (el === 'tunjangan') newString += 'Tunjangan '
        else newString += (el[0].toUpperCase() + el.split('').slice(1).join(''))
      })
      return newString
    },
    parsingDate(date) {
      return moment(date).locale('id').format('DD MMMM YYYY')
    },
    countDoubleJabatan(data) {
      let tunjanganJabatan = 0
      let tunjanganFungsional = 0
      let tunjanganOperasional = 0
      let tunjanganMakan = 0
      let tunjanganTransportasi = 0
      const pegawai = data.pegawai
      const rekapAbsen = data.rekap_absen
      let totalPenghasilan = rekapAbsen.rincian_gaji.total_penghasilan
      const totalPengurangan = rekapAbsen.rincian_gaji.total_pengurangan
      const totalGaji = rekapAbsen.rincian_gaji.total_gaji // penghasilan - pengurangan
      const { golongan } = pegawai
      if (golongan) {
        const gajiPokok = Number(golongan.gaji_pokok)
        // totalPenghasilan += gajiPokok

        if (pegawai.marital_tunjangan) {
          const gender = pegawai.jenis_kelamin === 'Laki-Laki' ? 'laki_laki' : pegawai.jenis_kelamin.toLowerCase()
          const genderAllowance = Number(pegawai.marital_tunjangan[gender]) / 100 * gajiPokok
          totalPenghasilan += genderAllowance

          for (const key in pegawai.marital_tunjangan) {
            if (key !== 'laki_laki' && key !== 'perempuan') totalPenghasilan += (Number(pegawai.marital_tunjangan[key]) / 100 * gajiPokok)
          }
        }
        // if (pegawai.jabatan_pegawais || pegawai.jabatan_pegawais.length) {
        for (let j = 0; j < pegawai.jabatan_pegawais.length; j++) {
          const dataTunjangan = pegawai.jabatan_pegawais[j].jabatan.jabatan_tunjangan

          // for (const key in dataTunjangan) {
          //   let satuanPerkalian = null
          //   if (key === 'tunjangan_makan') { satuanPerkalian = Number(rekapAbsen.hari_kerja_normal) } else if (key === 'tunjangan_transportasi') { satuanPerkalian = Number(rekapAbsen.jumlah_hari_kerja) } else if (key === 'tunjangan_operasional') { satuanPerkalian = 1 } else { satuanPerkalian = gajiPokok / 100 }
          //   totalPenghasilan += (Number(dataTunjangan[key]) * satuanPerkalian)
          // }
          tunjanganJabatan += dataTunjangan.tunjangan_jabatan * (gajiPokok / 100)
          tunjanganFungsional += dataTunjangan.tunjangan_fungsional * (gajiPokok / 100)
          tunjanganOperasional += dataTunjangan.tunjangan_operasional
          tunjanganMakan += dataTunjangan.tunjangan_makan * Number(rekapAbsen.hari_kerja_normal)
          tunjanganTransportasi += dataTunjangan.tunjangan_transportasi * Number(rekapAbsen.jumlah_hari_kerja)
        }
        // }
      }
      // total pengurangan section
      //

      // totalGaji = totalPenghasilan - totalPengurangan
      const dataUpdate = {
        tunjangan_jabatan: tunjanganJabatan,
        tunjangan_fungsional: tunjanganFungsional,
        tunjangan_operasional: tunjanganOperasional,
        tunjangan_makan: tunjanganMakan,
        tunjangan_transportasi: tunjanganTransportasi,
        total_penghasilan: totalPenghasilan,
        total_gaji: totalGaji,
        total_pengurangan: totalPengurangan,
      }
      return dataUpdate
    },
  },
}
</script>

<style lang="css">
.slip-container .table td {
  padding: 2px !important;
  font-size: 13px;
}

.slip-container .hr, .slip-container .border-top {
  height: 1px;
  background: black !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
</style>
